import React from 'react';
import DataTable from './components/DataTable';

function App() {
  return (
    <DataTable />
  )
}

export default App;
